// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  URL_SERVICIOS: 'https://fgtest.factorgfcleasing.com/api/v1',
  SECRET_KEY: 'a487efef14bed450a9709ca41f2619cb',
  URL_BASE_TUIDENTIDAD: 'https://dev.tuidentidad.com/api/',
  AWS: {
    accessKeyId: "",
    secretAccessKey: "",
    region: '',
    folder: '',
    bucketNomina: ''
  },
  firebaseConfig: {
    apiKey: "AIzaSyCqY01BkmNafwB6Tgx1pbwo3ZsURi_B3AE",
    authDomain: "testarrendamientoau.firebaseapp.com",
    projectId: "testarrendamientoau",
    storageBucket: "testarrendamientoau.appspot.com",
    messagingSenderId: "691276244381",
    appId: "1:691276244381:web:f655b164211b6998c57b74",
    measurementId: "G-XS0FJ4WEYS"
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
